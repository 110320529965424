import { default as ai_45form_45buildercLHWC1CUhrMeta } from "/Users/sz/Code/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editlagxFmO8v1Meta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index3gH0MI7j9GMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexqVWJjleCnjMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexQcMaNj82FJMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareYoeP3FQZ9JMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statspDuwnHrjdaMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsT262jEzSzTMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showC518AN0pQrMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as edithnrUO5me97Meta } from "/Users/sz/Code/OpnForm/client/pages/forms/[slug]/submission/edit.vue?macro=true";
import { default as guest5vQDCM40T0Meta } from "/Users/sz/Code/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as index13PxQuyFdUMeta } from "/Users/sz/Code/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homekdkH9hJdGbMeta } from "/Users/sz/Code/OpnForm/client/pages/home.vue?macro=true";
import { default as indexqWbExfWqIrMeta } from "/Users/sz/Code/OpnForm/client/pages/index.vue?macro=true";
import { default as logino7qsu8iWUTMeta } from "/Users/sz/Code/OpnForm/client/pages/login.vue?macro=true";
import { default as email0RDv0D3ARdMeta } from "/Users/sz/Code/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93dgcuJ0aOYGMeta } from "/Users/sz/Code/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingLsUFLuDyOPMeta } from "/Users/sz/Code/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyQ8pVjGxSaaMeta } from "/Users/sz/Code/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as accountsh334mT0H9Meta } from "/Users/sz/Code/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminVl0lp3nkXfMeta } from "/Users/sz/Code/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billing1MvseSdjKMMeta } from "/Users/sz/Code/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as indexL7PhzZEmfqMeta } from "/Users/sz/Code/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwords5ayniW7GdMeta } from "/Users/sz/Code/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profileZucqzdrMLOMeta } from "/Users/sz/Code/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspace5QXCOEok23Meta } from "/Users/sz/Code/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsEl5l0cGlOEMeta } from "/Users/sz/Code/OpnForm/client/pages/settings.vue?macro=true";
import { default as errorWtoiZWrALgMeta } from "/Users/sz/Code/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as success68Tjf9LNGoMeta } from "/Users/sz/Code/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93GbD5qbpHaLMeta } from "/Users/sz/Code/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexWpBhG76T5VMeta } from "/Users/sz/Code/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93pq0iHGiPvSMeta } from "/Users/sz/Code/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templateszgriY6ESmGMeta } from "/Users/sz/Code/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93s99jlj0dIjMeta } from "/Users/sz/Code/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsO8LEWIJ2eqMeta } from "/Users/sz/Code/OpnForm/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: ai_45form_45buildercLHWC1CUhrMeta?.name ?? "ai-form-builder",
    path: ai_45form_45buildercLHWC1CUhrMeta?.path ?? "/ai-form-builder",
    meta: ai_45form_45buildercLHWC1CUhrMeta || {},
    alias: ai_45form_45buildercLHWC1CUhrMeta?.alias || [],
    redirect: ai_45form_45buildercLHWC1CUhrMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: editlagxFmO8v1Meta?.name ?? "forms-slug-edit",
    path: editlagxFmO8v1Meta?.path ?? "/forms/:slug()/edit",
    meta: editlagxFmO8v1Meta || {},
    alias: editlagxFmO8v1Meta?.alias || [],
    redirect: editlagxFmO8v1Meta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index3gH0MI7j9GMeta?.name ?? "forms-slug",
    path: index3gH0MI7j9GMeta?.path ?? "/forms/:slug()",
    meta: index3gH0MI7j9GMeta || {},
    alias: index3gH0MI7j9GMeta?.alias || [],
    redirect: index3gH0MI7j9GMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showC518AN0pQrMeta?.name ?? undefined,
    path: showC518AN0pQrMeta?.path ?? "/forms/:slug()/show",
    meta: showC518AN0pQrMeta || {},
    alias: showC518AN0pQrMeta?.alias || [],
    redirect: showC518AN0pQrMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexqVWJjleCnjMeta?.name ?? "forms-slug-show",
    path: indexqVWJjleCnjMeta?.path ?? "",
    meta: indexqVWJjleCnjMeta || {},
    alias: indexqVWJjleCnjMeta?.alias || [],
    redirect: indexqVWJjleCnjMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexQcMaNj82FJMeta?.name ?? "forms-slug-show-integrations",
    path: indexQcMaNj82FJMeta?.path ?? "integrations",
    meta: indexQcMaNj82FJMeta || {},
    alias: indexQcMaNj82FJMeta?.alias || [],
    redirect: indexQcMaNj82FJMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareYoeP3FQZ9JMeta?.name ?? "forms-slug-show-share",
    path: shareYoeP3FQZ9JMeta?.path ?? "share",
    meta: shareYoeP3FQZ9JMeta || {},
    alias: shareYoeP3FQZ9JMeta?.alias || [],
    redirect: shareYoeP3FQZ9JMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: statspDuwnHrjdaMeta?.name ?? "forms-slug-show-stats",
    path: statspDuwnHrjdaMeta?.path ?? "stats",
    meta: statspDuwnHrjdaMeta || {},
    alias: statspDuwnHrjdaMeta?.alias || [],
    redirect: statspDuwnHrjdaMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: submissionsT262jEzSzTMeta?.name ?? "forms-slug-show-submissions",
    path: submissionsT262jEzSzTMeta?.path ?? "submissions",
    meta: submissionsT262jEzSzTMeta || {},
    alias: submissionsT262jEzSzTMeta?.alias || [],
    redirect: submissionsT262jEzSzTMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: edithnrUO5me97Meta?.name ?? "forms-slug-submission-edit",
    path: edithnrUO5me97Meta?.path ?? "/forms/:slug()/submission/edit",
    meta: edithnrUO5me97Meta || {},
    alias: edithnrUO5me97Meta?.alias || [],
    redirect: edithnrUO5me97Meta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/[slug]/submission/edit.vue").then(m => m.default || m)
  },
  {
    name: guest5vQDCM40T0Meta?.name ?? "forms-create-guest",
    path: guest5vQDCM40T0Meta?.path ?? "/forms/create/guest",
    meta: guest5vQDCM40T0Meta || {},
    alias: guest5vQDCM40T0Meta?.alias || [],
    redirect: guest5vQDCM40T0Meta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: index13PxQuyFdUMeta?.name ?? "forms-create",
    path: index13PxQuyFdUMeta?.path ?? "/forms/create",
    meta: index13PxQuyFdUMeta || {},
    alias: index13PxQuyFdUMeta?.alias || [],
    redirect: index13PxQuyFdUMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: homekdkH9hJdGbMeta?.name ?? "home",
    path: homekdkH9hJdGbMeta?.path ?? "/home",
    meta: homekdkH9hJdGbMeta || {},
    alias: homekdkH9hJdGbMeta?.alias || [],
    redirect: homekdkH9hJdGbMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexqWbExfWqIrMeta?.name ?? "index",
    path: indexqWbExfWqIrMeta?.path ?? "/",
    meta: indexqWbExfWqIrMeta || {},
    alias: indexqWbExfWqIrMeta?.alias || [],
    redirect: indexqWbExfWqIrMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logino7qsu8iWUTMeta?.name ?? "login",
    path: logino7qsu8iWUTMeta?.path ?? "/login",
    meta: logino7qsu8iWUTMeta || {},
    alias: logino7qsu8iWUTMeta?.alias || [],
    redirect: logino7qsu8iWUTMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: email0RDv0D3ARdMeta?.name ?? "password-email",
    path: email0RDv0D3ARdMeta?.path ?? "/password/email",
    meta: email0RDv0D3ARdMeta || {},
    alias: email0RDv0D3ARdMeta?.alias || [],
    redirect: email0RDv0D3ARdMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: _91token_93dgcuJ0aOYGMeta?.name ?? "password-reset-token",
    path: _91token_93dgcuJ0aOYGMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93dgcuJ0aOYGMeta || {},
    alias: _91token_93dgcuJ0aOYGMeta?.alias || [],
    redirect: _91token_93dgcuJ0aOYGMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: pricingLsUFLuDyOPMeta?.name ?? "pricing",
    path: pricingLsUFLuDyOPMeta?.path ?? "/pricing",
    meta: pricingLsUFLuDyOPMeta || {},
    alias: pricingLsUFLuDyOPMeta?.alias || [],
    redirect: pricingLsUFLuDyOPMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQ8pVjGxSaaMeta?.name ?? "privacy-policy",
    path: privacy_45policyQ8pVjGxSaaMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQ8pVjGxSaaMeta || {},
    alias: privacy_45policyQ8pVjGxSaaMeta?.alias || [],
    redirect: privacy_45policyQ8pVjGxSaaMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: settingsEl5l0cGlOEMeta?.name ?? undefined,
    path: settingsEl5l0cGlOEMeta?.path ?? "/settings",
    meta: settingsEl5l0cGlOEMeta || {},
    alias: settingsEl5l0cGlOEMeta?.alias || [],
    redirect: settingsEl5l0cGlOEMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: accountsh334mT0H9Meta?.name ?? "settings-account",
    path: accountsh334mT0H9Meta?.path ?? "account",
    meta: accountsh334mT0H9Meta || {},
    alias: accountsh334mT0H9Meta?.alias || [],
    redirect: accountsh334mT0H9Meta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminVl0lp3nkXfMeta?.name ?? "settings-admin",
    path: adminVl0lp3nkXfMeta?.path ?? "admin",
    meta: adminVl0lp3nkXfMeta || {},
    alias: adminVl0lp3nkXfMeta?.alias || [],
    redirect: adminVl0lp3nkXfMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billing1MvseSdjKMMeta?.name ?? "settings-billing",
    path: billing1MvseSdjKMMeta?.path ?? "billing",
    meta: billing1MvseSdjKMMeta || {},
    alias: billing1MvseSdjKMMeta?.alias || [],
    redirect: billing1MvseSdjKMMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: indexL7PhzZEmfqMeta?.name ?? "settings",
    path: indexL7PhzZEmfqMeta?.path ?? "",
    meta: indexL7PhzZEmfqMeta || {},
    alias: indexL7PhzZEmfqMeta?.alias || [],
    redirect: indexL7PhzZEmfqMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwords5ayniW7GdMeta?.name ?? "settings-password",
    path: passwords5ayniW7GdMeta?.path ?? "password",
    meta: passwords5ayniW7GdMeta || {},
    alias: passwords5ayniW7GdMeta?.alias || [],
    redirect: passwords5ayniW7GdMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileZucqzdrMLOMeta?.name ?? "settings-profile",
    path: profileZucqzdrMLOMeta?.path ?? "profile",
    meta: profileZucqzdrMLOMeta || {},
    alias: profileZucqzdrMLOMeta?.alias || [],
    redirect: profileZucqzdrMLOMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspace5QXCOEok23Meta?.name ?? "settings-workspace",
    path: workspace5QXCOEok23Meta?.path ?? "workspace",
    meta: workspace5QXCOEok23Meta || {},
    alias: workspace5QXCOEok23Meta?.alias || [],
    redirect: workspace5QXCOEok23Meta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorWtoiZWrALgMeta?.name ?? "subscriptions-error",
    path: errorWtoiZWrALgMeta?.path ?? "/subscriptions/error",
    meta: errorWtoiZWrALgMeta || {},
    alias: errorWtoiZWrALgMeta?.alias || [],
    redirect: errorWtoiZWrALgMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: success68Tjf9LNGoMeta?.name ?? "subscriptions-success",
    path: success68Tjf9LNGoMeta?.path ?? "/subscriptions/success",
    meta: success68Tjf9LNGoMeta || {},
    alias: success68Tjf9LNGoMeta?.alias || [],
    redirect: success68Tjf9LNGoMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GbD5qbpHaLMeta?.name ?? "templates-slug",
    path: _91slug_93GbD5qbpHaLMeta?.path ?? "/templates/:slug()",
    meta: _91slug_93GbD5qbpHaLMeta || {},
    alias: _91slug_93GbD5qbpHaLMeta?.alias || [],
    redirect: _91slug_93GbD5qbpHaLMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWpBhG76T5VMeta?.name ?? "templates",
    path: indexWpBhG76T5VMeta?.path ?? "/templates",
    meta: indexWpBhG76T5VMeta || {},
    alias: indexWpBhG76T5VMeta?.alias || [],
    redirect: indexWpBhG76T5VMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pq0iHGiPvSMeta?.name ?? "templates-industries-slug",
    path: _91slug_93pq0iHGiPvSMeta?.path ?? "/templates/industries/:slug()",
    meta: _91slug_93pq0iHGiPvSMeta || {},
    alias: _91slug_93pq0iHGiPvSMeta?.alias || [],
    redirect: _91slug_93pq0iHGiPvSMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45templateszgriY6ESmGMeta?.name ?? "templates-my-templates",
    path: my_45templateszgriY6ESmGMeta?.path ?? "/templates/my-templates",
    meta: my_45templateszgriY6ESmGMeta || {},
    alias: my_45templateszgriY6ESmGMeta?.alias || [],
    redirect: my_45templateszgriY6ESmGMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s99jlj0dIjMeta?.name ?? "templates-types-slug",
    path: _91slug_93s99jlj0dIjMeta?.path ?? "/templates/types/:slug()",
    meta: _91slug_93s99jlj0dIjMeta || {},
    alias: _91slug_93s99jlj0dIjMeta?.alias || [],
    redirect: _91slug_93s99jlj0dIjMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsO8LEWIJ2eqMeta?.name ?? "terms-conditions",
    path: terms_45conditionsO8LEWIJ2eqMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsO8LEWIJ2eqMeta || {},
    alias: terms_45conditionsO8LEWIJ2eqMeta?.alias || [],
    redirect: terms_45conditionsO8LEWIJ2eqMeta?.redirect,
    component: () => import("/Users/sz/Code/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]